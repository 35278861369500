body {
	font-family: 'Open Sans', sans-serif;
	color: #616163;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 400;
}

h1 {
	margin-top: 1.5rem;
	margin-bottom: 1.25rem;
	color: #242425;
	font-size: 2.375rem;
	line-height: 3rem;
	font-weight: 400;
}

h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-size: 1.75rem;
	line-height: 2.375rem;
	font-family: 'Open Sans', sans-serif;
	color: #242425;
	font-weight: 400;
}

h2.page-title {
	margin-top: 1.5rem;
	margin-bottom: 1.25rem;
	color: #242425;
	font-size: 2.375rem;
	line-height: 3rem;
	font-weight: 400;
}

h3 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 1.5rem;
	line-height: 2.125rem;
	color: #242425;
	font-weight: 400;
}

h4 {
	margin-top: 15px;
	margin-bottom: 10px;
	font-size: 1.125rem;
	line-height: 1.625rem;
	color: #616163;
	font-weight: 600;
	text-transform: none;
}

h5 {
	margin-top: 15px;
	margin-bottom: 10px;
	font-size: 0.95rem;
	line-height: 1.545rem;
	color: #616163;
	font-weight: 600;
}

h6 {
	margin-top: 15px;
	margin-bottom: 10px;
	color: #616163;
	font-size: 0.9rem;
	line-height: 1.525rem;
	font-weight: 600;
}

p {
	margin-bottom: 10px;
	line-height: 1.625rem;
	letter-spacing: 0.01rem;
	color: #616163;
}

a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	text-decoration: underline;
}

a:hover {
	color: #975007;
}

ul {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 20px;
}

ol {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 20px;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 10px 20px;
	border-left: 5px solid #ce3e16;
	font-size: 1.125rem;
	line-height: 1.75rem;
	color: #616163;
}

.nav-bar-widget {
	border-bottom: 4px solid #616163;
	background-color: #fff;
	background-image: url('../images/digital-map_header.png');
	background-position: 50% 0px;
	background-size: cover;
	background-repeat: no-repeat;
}

.main-nav-dd-toggle {
	margin-right: 1px;
	margin-left: 1px;
	padding: 0px;
	-webkit-transition: box-shadow 200ms ease;
	transition: box-shadow 200ms ease;
	color: #616163;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-dd-toggle:hover {
	box-shadow: inset 0 -6px 0 0 #ce3e16;
	color: #616163;
}

.main-nav-dd-toggle.w--current {
	display: block;
	box-shadow: inset 0 -6px 0 0 #a6b0c0;
	color: #616163;
}

.main-nav-dd-toggle.w--open {
	box-shadow: inset 0 -6px 0 0 #ce3e16;
}

.secondary-nav-wrap {
	margin-top: 10px;
	float: right;
}

.secondary-nav-link {
	display: inline;
	padding-right: 8px;
	padding-left: 8px;
	color: #595d63;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
	float: left;
}

.secondary-nav-link:hover {
	color: #ce3e16;
}

.secondary-nav-link.w--current {
	color: #ce3e16;
}

.secondary-nav-list {
	margin-right: 12px;
	margin-bottom: 4px;
}

.nav-menu {
	margin-top: 18px;
	margin-bottom: 16px;
	clear: right;
}

.logo-home-link {
	position: relative;
	width: 230px;
	margin-top: 18px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.logo-home-link.w--current {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.slider-section {
	box-shadow: inset 0 5px 9px 0 rgba(0, 0, 0, 0.07);
	overflow: hidden;
}

.section-1 {
	padding: 20px 4vw;
	background-image: url('../images/digital-map_middle.png');
	background-position: 50% 0px;
	background-size: cover;
	background-repeat: no-repeat;
}

.section-1.portfolio-section {
	height: 245px;
	background-image: -webkit-linear-gradient(270deg, rgba(253, 137, 2, 0.76), rgba(253, 137, 2, 0.76)), url('../images/worn_dots.png'), url('../images/1600bw_Thinkstock-473505660.jpg');
	background-image: linear-gradient(180deg, rgba(253, 137, 2, 0.76), rgba(253, 137, 2, 0.76)), url('../images/worn_dots.png'), url('../images/1600bw_Thinkstock-473505660.jpg');
	background-position: 0px 0px, 0px 0px, 50% 0px;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, fixed;
}

.section-1.cta-section {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.section-1.cta-section-plugins {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.hr-divider {
	display: block;
	width: 64px;
	height: 1px;
	margin-right: auto;
	margin-bottom: 12px;
	margin-left: auto;
	background-color: #c7c7c7;
}

.hr-divider.hr-divider-long {
	width: 100%;
	margin-top: 3.25rem;
	margin-bottom: 1.75rem;
}

.learn-more-text-link {
	position: static;
	margin-top: 2px;
	padding-left: 22px;
	background-image: url('../images/circle_arrow_gray_1circle_arrow_gray.png');
	background-position: 0px 50%;
	background-size: 17px 17px;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #a6b0c0;
	font-size: 0.875rem;
	font-weight: 700;
	text-align: left;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.learn-more-text-link:hover {
	background-image: url('../images/circle_arrow_orange-2_1circle_arrow_orange 2.png');
	background-size: 17px 17px;
	color: #ce3e16;
}

.main-content-wrapper {
	position: relative;
	z-index: 1000;
	padding-top: 85px;
}

.main-content-wrapper.inside-main {
	padding-top: 85px;
}

.section-2 {
	padding-right: 4vw;
	padding-bottom: 60px;
	padding-left: 4vw;
}

.footer-section {
	position: relative;
	background-image: url('../images/digital-map_footer.png');
	background-position: 50% 0px;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: inset 0 5px 9px 0 rgba(0, 0, 0, 0.07);
}

.map-pin-wrapper {
	display: none;
	margin-top: -3px;
	margin-right: 8px;
	margin-bottom: 40px;
	float: left;
}

.footer-paragraph {
	float: left;
	color: #9a9da4;
	font-size: 0.875rem;
	line-height: 1.5rem;
	letter-spacing: 0.01rem;
}

.email-link {
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: hsla(0, 0%, 100%, 0.7);
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.email-link:hover {
	color: #fff;
}

.footer-nav-list {
	margin-bottom: 0px;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.footer-nav-list-item {
	letter-spacing: 0.02rem;
}

.footer-list-link {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #9a9da4;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-list-link:hover {
	color: #fff;
}

.footer-list-link.w--current {
	color: #fff;
}

.vertical-pipe {
	display: inline-block;
	margin-right: 12px;
	padding-right: 2px;
	padding-left: 2px;
	float: left;
}

.vertical-pipe.header {
	margin-right: 0px;
}

.secondary-nav-list-item {
	float: left;
	font-size: 12px;
	line-height: 16px;
}

.signoff-dark-bar {
	background-color: rgba(0, 0, 0, 0.15);
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 10px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 0.3;
}

.social-media-link-block.youtube {
	margin-top: 2px;
}

.social-media-link-block:hover {
	opacity: 1;
}

.signoff-text {
	margin-top: 6px;
	margin-right: 4px;
	float: left;
	color: #747479;
	font-size: 12px;
	line-height: 16px;
}

.signoff-text h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	color: inherit;
	line-height: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
}


.grip-footer-lockup {
	float: right;
}

.footer-column-3 {
	width: 30%;
	min-height: 106px;
	padding-bottom: 16px;
	padding-left: 16px;
	border-left: 1px solid #595959;
}

.slider-image-div {
	position: relative;
	width: 50%;
	height: 100%;
	background-image: url('../images/example-bg.png');
	background-position: 0px 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.slider-image-div.slide-1 {
	width: 50%;
	background-position: 50% 50%;
}

.slider-header {
	display: inline-block;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 2px 12px 4px 10px;
	float: left;
	clear: left;
	background-color: #353840;
	color: #fff;
	font-size: 2.75rem;
	line-height: 3rem;
	font-weight: 300;
	text-transform: uppercase;
}

.slider-header-emphasis {
	font-weight: 700;
}

.slider-paragraph {
	max-width: 450px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	margin-left: 11px;
	float: left;
	clear: left;
	color: #17191d;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.slider-cta-link {
	margin-top: 7px;
	margin-left: 11px;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 30px;
	float: left;
	clear: left;
	background-image: url('../images/circle-arrow-595d63gray.svg');
	background-position: 0px 50%;
	background-size: 21px 21px;
	background-repeat: no-repeat;
	color: #595d63;
	font-size: 1rem;
	line-height: 1.125rem;
	font-weight: 700;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.slider-cta-link:hover {
	opacity: 0.6;
	color: #595d63;
}

.cta-bar-header {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 0rem;
	color: #fff;
	font-size: 2rem;
	font-weight: 300;
	letter-spacing: 0.04rem;
}

.cta-emphasis {
	font-weight: 400;
}

.button {
	position: relative;
	display: inline-block;
	height: 38px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 12px 24px 11px;
	background-color: #ce3e16;
	box-shadow: 0 3px 0 0 #997449;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	color: #fff;
	text-shadow: 0 0 6px hsla(0, 0%, 100%, 0.9);
}

.button.secondary {
	position: relative;
	background-color: #aaafb8;
	background-image: none;
	box-shadow: 0 3px 0 0 #79808b;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	text-transform: uppercase;
}

.button.secondary:hover {
	color: #fff;
}

.button.special-button {
	position: static;
	display: block;
	height: 100%;
	margin-top: 0rem;
	margin-right: 0px;
	margin-bottom: 0rem;
	padding: 10px 70px 0px 16px;
	background-color: transparent;
	background-image: url('../images/button-rocket_1button-rocket.png');
	background-position: 92% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	box-shadow: none;
	-webkit-transition-property: none;
	transition-property: none;
	font-size: 1.125rem;
	line-height: 1.5rem;
	text-align: left;
	text-transform: none;
}

.button.special-button:hover {
	background-image: url('../images/button-rocket-glow2_1button-rocket-glow2.png');
	background-position: 97% 50%;
	background-size: 42px 42px;
}

.button.tertiary {
	display: inline-block;
	padding-right: 12px;
	padding-left: 12px;
	background-color: #aaafb8;
	box-shadow: none;
	color: #fff;
	line-height: 14px;
}

.button.dark-button {
	width: 80%;
	max-width: 460px;
	margin-right: 0rem;
	float: right;
	background-color: #353840;
	box-shadow: 0 3px 0 0 #000;
}

.button.no-margin {
	margin-right: 0rem;
}

.special-button-wrapper {
	height: 46px;
	min-width: 200px;
	margin-left: 2rem;
	float: right;
	background-color: #f8992a;
	box-shadow: inset -57px 0 0 0 #d38429, 0 3px 0 0 #997449;
}

.section-2-header {
	display: block;
	max-width: 820px;
	margin-top: 36px;
	margin-right: auto;
	margin-left: auto;
	color: #a6b0c0;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.04rem;
}

.section-2-subhead {
	display: block;
	max-width: 600px;
	margin-right: auto;
	margin-left: auto;
	color: #353840;
	font-size: 1.125rem;
	line-height: 1.625rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.03rem;
	text-transform: none;
}

.icon-circle-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 133px;
	height: 133px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border: 3px solid #e3e7ee;
	border-radius: 67px;
}

.icon-circle-wrapper.icon-1 {
	border-color: #e3e7ee;
	background-position: 50% 50%;
	background-size: 82px;
	background-repeat: no-repeat;
}

.icon-circle-wrapper.icon-2 {
	background-position: 50% 40%;
	background-size: 82px;
	background-repeat: no-repeat;
}

.icon-circle-wrapper.icon-3 {
	background-position: 50% 50%;
	background-size: 80px;
	background-repeat: no-repeat;
}

.icon-circle-wrapper.icon-4 {
	background-position: 50% 50%;
	background-size: 80px;
	background-repeat: no-repeat;
}

.icon-circle-wrapper.icon-5 {
	background-position: 50% 50%;
	background-size: 80px;
	background-repeat: no-repeat;
}

.icon-circle-wrapper.icon-6 {
	background-position: 50% 50%;
	background-size: 80px;
	background-repeat: no-repeat;
}

.icon-circle-wrapper.icon-7 {
	background-position: 50% 50%;
	background-size: 80px;
	background-repeat: no-repeat;
}

.icon-circle-wrapper.icon-8 {
	background-position: 50% 40%;
	background-size: 68px;
	background-repeat: no-repeat;
}

.section-2-cb-heading {
	margin-top: 12px;
	margin-bottom: 5px;
	color: #f8992a;
	font-size: 1.4rem;
	font-weight: 400;
	letter-spacing: 0.04rem;
}

.section-2-paragraph {
	min-width: 210px;
	padding-right: 1vw;
	padding-left: 1.5vw;
	color: #6b6b6b;
	font-size: 0.85rem;
	line-height: 1.45rem;
	font-weight: 600;
	text-align: left;
}

.section-2-paragraph a {
	min-width: 210px;
	padding-right: 1vw;
	padding-left: 1.5vw;
	color: #6b6b6b;
	font-size: 0.85rem;
	line-height: 1.45rem;
	font-weight: 600;
	text-align: left;
	text-decoration: none;
}

.section-2-column {
	position: relative;
	width: 25%;
	padding-right: 1.25rem;
	padding-bottom: 2.25rem;
	padding-left: 1.25rem;
	text-align: center;
}

.section-2-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: space-evenly;
	display: flex;
	margin-top: 40px;
	margin-bottom: 3rem;
	padding-bottom: 3rem;
	border-bottom: 1px solid #c7c7c7;
}

.section-2-row.row-2-last {
	margin-bottom: 0rem;
	border-bottom-style: none;
}

.section-3-header {
	color: #17191d;
	font-size: 2.375rem;
	line-height: 3rem;
	font-weight: 400;
	letter-spacing: 0.04rem;
	margin-top: 1.5rem;
	margin-bottom: 1.25rem;
}

.section-3-paragraph {
	max-width: 450px;
	color: #17191d;
	font-size: 1.125rem;
	line-height: 1.625rem;
}

.section-3-image {
	width: 100%;
	float: right;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
}

.section-3-column-2 {
	width: 50%;
	padding-right: 0px;
	padding-left: 0px;
	text-align: center;
}

.footer-row-1 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 38px;
	margin-bottom: 40px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-row-2 {
	margin-top: 16px;
	margin-bottom: 16px;
}

.section-3-column-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 100%;
	min-height: 375px;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.header-container {
	max-width: 1400px;
	padding-right: 1rem;
	padding-left: 2rem;
}

.header-section {
	position: fixed;
	z-index: 10000;
	width: 100%;
}

.cta-row {
	height: 100%;
}

.section-1-container {
	max-width: 1400px;
}

.section-1-container.portfolio-container {
	max-width: 1200px;
	padding-top: 47px;
}

.cta-column-1 {
	height: 100%;
}

.cta-column-1.portfolio-cta-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cta-column-2 {
	height: 100%;
}

.cta-column-2.portfolio-column-2 {
	display: block;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.section-2-container {
	max-width: 1260px;
}

.footer-container {
	max-width: 1400px;
	padding-right: 4vw;
	padding-left: 4vw;
}

.learn-moretext-link-wrapper {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	text-align: center;
}

.phone-link {
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #9a9da4;
	text-decoration: none;
}

.phone-link.desktop,
.phone-link.desktop:hover {
	color: #9a9da4;
}

.phone-link:hover {
	color: #fff;
}

.footer-column-1 {
	margin-right: 3rem;
	padding-left: 20px;
}

.footer-nested-column {
	width: 60%;
}

.footer-nested-column.footer-nest-col-2 {
	width: 40%;
}

.dropdown-list {
	display: none;
	min-width: 170px;
}

.dropdown-list.w--open {
	min-width: 100%;
	background-color: #e6ebf5;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
}

.dropdown-list.megamenu {
	left: -396px;
	background-color: #e6ebf5;
}

.dropdown-link {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid hsla(219, 9%, 69%, 0.5);
	color: #242425;
	font-size: 0.95rem;
	font-weight: 400;
}

.dropdown-link:hover {
	background-color: #ccd1db;
	color: #242425;
}

.dropdown-link.w--current {
	margin-bottom: 2px;
	background-color: #ccd1db;
	color: #242425;
}

.section-2-header-wrapper {
	padding-right: 2rem;
	padding-left: 2rem;
}

.inside-header-bar {
	position: relative;
	z-index: 999;
	display: none;
	height: 16px;
	background-color: #ce3e16;
}

.inside-main-content-container {
	max-width: 1400px;
	padding: 2.1875rem 8vw 4rem;
	background-color: #fff;
}

.inside-main-content-section {
	position: relative;
	min-height: 100%;
	background-color: #e3e7ee;
}

.inside-page-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.inside-page-row.no-sidebars {
	display: block;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
}

.inside-page-left-column {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	min-width: 210px;
	margin-right: 3vw;
	padding-right: 0px;
	padding-left: 0px;
	flex-direction: column;
}

.inside-page-left-column .left-nav-wrapper,
.inside-page-left-column .box {
	margin-bottom: 2rem;
}

.inside-page-content-column {
	width: 100%;
	padding-right: 0px;
}

.left-nav-wrapper {
	width: 100%;
}

.left-nav-list {
	margin-bottom: 0px;
	font-size: 1rem;
	line-height: 1.6rem;
	letter-spacing: 0rem;
}

.left-nav-list.grandchildren-list {
	margin-left: 0.75rem;
	padding-left: 0px;
	font-size: 16px;
	text-align: left;
}

.left-nav-list.greatgrandchld-list {
	margin-left: 1rem;
	font-size: 16px;
	text-align: left;
}

.left-nav-list-item {
	font-size: 16px;
	line-height: 18px;
	font-weight: 600;
	text-align: left;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
}

.left-nav-link {
	display: block;
	padding: 8px 4px 8px 12px;
	color: #242425 !important;
	font-size: 0.95rem;
	line-height: 1.3rem;
	font-weight: 400;
	letter-spacing: 0rem;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link:hover {
	-webkit-transform: translate(-8px, 0px);
	-ms-transform: translate(-8px, 0px);
	transform: translate(-8px, 0px);
	color: #ce3e16 !important;
}

.left-nav-link.w--current {
	background-color: rgba(227, 231, 238, 0.5);
	color: #17191d !important;
}

.left-nav-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #242425;
}

.left-nav-link.grandchild-link {
	font-size: 0.88rem;
	line-height: 1.13rem;
}

.left-nav-link.grandchild-link:hover {
	color: #ce3e16;
}

.left-nav-link.grandchild-link.w--current:hover {
	color: #fff;
}

.left-nav-link.section-title-link {
	box-shadow: none;
	color: #ce3e16;
}

.left-nav-link.section-title-link:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link.section-title-link.w--current {
	background-color: transparent;
}

.left-nav-link.section-title-link.w--current:hover {
	color: #ce3e16;
}

.left-nav-link.ggc-link {
	font-size: 0.81rem;
	line-height: 1.16rem;
}

.breadcrumbs-list {
	font-size: 0.82rem;
	line-height: 1.2rem;
}

.breadcrumbs-list-item {
	display: inline-block;
}

.inside-page-row .breadcrumb-link {
	color: #616163;
	text-decoration: none;
}

.inside-page-row .breadcrumb-link:hover {
	color: #ce3e16;
}

.inside-page-row .breadcrumb-link.w--current {
	color: #ce3e16;
	font-weight: 700;
}

.inside-page-row .breadcrumb-link.current-breadcrumb {
	color: #ce3e16;
	font-weight: 700;
}

.intro-paragraph {
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.875rem;
	font-weight: 400;
}

.field-label {
	font-size: 0.95rem;
	font-weight: 600;
}

.inside-page-nested-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.inside-page-nested-row.landing-parent {
	padding-top: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.session-message {
	margin-top: 10px;
	padding-top: 16px;
	padding-bottom: 16px;
	text-align: left;
}

.session-message.error {
	padding-right: 20px;
	padding-left: 20px;
	background-image: -webkit-linear-gradient(270deg, #ee432c, #ce3a3a);
	background-image: linear-gradient(180deg, #ee432c, #ce3a3a);
}

.session-message.success {
	background-image: -webkit-linear-gradient(270deg, #69cf5b, #63b858);
	background-image: linear-gradient(180deg, #69cf5b, #63b858);
}

.session-message-paragraph {
	margin-bottom: 0px;
	padding-left: 34px;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
}

.session-icon-div {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	float: left;
	line-height: 14px;
}

.portfolio-cta-paragraph {
	position: static;
	margin-top: 0px;
	float: right;
	color: #17191d;
	font-size: 1.375rem;
	line-height: 2.125rem;
	font-weight: 400;
}

.portfolio-cta-header {
	margin-top: 0px;
	margin-bottom: 16px;
	color: #17191d;
	font-size: 2.375rem;
	line-height: 2.75rem;
	font-weight: 400;
}

.text-highlight {
	color: #fff;
	font-weight: 700;
}

.portfolio-text-wrapper {
	min-width: 560px;
}

.demo-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.slider-container {
	display: block;
	max-width: 1320px;
	margin-right: auto;
	margin-left: auto;
}

.container.edition-landing-hero-caption-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 4vw;
	padding-left: 4vw;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.plugins-container .box.pages-content-boxes {
	border-top: 1px solid #c7c7c7;
	padding-top: 2rem;
	margin-top: 2rem;
}

.plugin-wrap,
.industry-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.module-icon-wrap {
	width: 145px;
	height: 145px;
	margin-right: 2rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.inside-page-row a.learn-more-text-link-arrow-end,
.inside-page-row .plugin-summary-wrap a {
	position: static;
	display: inline-block;
	margin-top: 0.5rem;
	padding-right: 22px;
	background-image: url('../images/circle_arrow_dk-gray_1circle_arrow_dk-gray.png');
	background-position: 100% 50%;
	background-size: 17px 17px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #707785;
	font-size: 15px;
	font-weight: 700;
	text-align: left;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	border-bottom: 0px solid;
}

.inside-page-row a.learn-more-text-link-arrow-end:hover,
.inside-page-row .plugin-summary-wrap a:hover {
	background-image: url('../images/circle_arrow_orange-2_1circle_arrow_orange 2.png');
	background-size: 17px 17px;
	color: #ce3e16;
}

.unordered-list-flush {
	padding-left: 20px;
}

.hr-divider-full-width {
	display: block;
	width: 100%;
	height: 1px;
	margin: 2rem auto;
	background-color: #c7c7c7;
}

.container-2 {
	max-width: 1400px;
	padding-right: 4vw;
	padding-left: 4vw;
}

.slide-text-wrap {
	display: block;
	width: 50%;
	padding-right: 3vw;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.landing-page-hero {
	position: relative;
	height: 480px;
	background-color: #e6ebf5;
	background-image: url('../images/random-grey-variations-wht50.png'), -webkit-linear-gradient(270deg, rgba(238, 148, 43, 0.68), rgba(238, 148, 43, 0.68));
	background-image: url('../images/random-grey-variations-wht50.png'), linear-gradient(180deg, rgba(238, 148, 43, 0.68), rgba(238, 148, 43, 0.68));
	background-position: 0px 0px, 0px 0px;
	background-size: 100px, auto;
	background-repeat: repeat, repeat;
	box-shadow: inset 0 5px 9px 0 rgba(0, 0, 0, 0.07);
}

.landing-page-hero.md {
	background-color: #e6ebf5;
	background-image: url('../images/random-grey-variations-wht50.png'), -webkit-linear-gradient(270deg, rgba(37, 43, 56, 0.46), rgba(37, 43, 56, 0.46)), url('../images/BW_TS-600073852.jpg');
	background-image: url('../images/random-grey-variations-wht50.png'), linear-gradient(180deg, rgba(37, 43, 56, 0.46), rgba(37, 43, 56, 0.46)), url('../images/BW_TS-600073852.jpg');
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: 100px, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.module-image-small,
.module-icon-wrap img {
	width: 100%;
	margin-right: 2rem;
}

.xl-intro-paragraph {
	margin-top: 20px;
	margin-bottom: 32px;
	color: #616363;
	font-size: 22px;
	line-height: 34px;
	font-weight: 400;
}

.landing-hero-icon {
	width: 100%;
}

.landing-main-icon-wrap {
	display: inline-block;
	width: 200px;
	height: 200px;
	margin-bottom: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border: 6px solid #fff;
	border-radius: 50%;
}

.main-nav-link {
	padding: 10px 20px;
	color: #595d63;
	text-decoration: none;
}

.main-nav-link:hover {
	color: #616163;
}

.main-nav-link.w--current {
	box-shadow: none;
	color: #ce3e16;
}

.main-nav-link.w--current:hover {
	box-shadow: inset 0 -6px 0 0 #ce3e16;
}

.landing-hero-caption-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.landing-hero-header {
	display: inline-block;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 2px 12px 4px;
	clear: left;
	background-color: #353840;
	color: #fff;
	font-size: 44px;
	line-height: 48px;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.edition-icon-wrap {
	width: 145px;
	height: 145px;
	margin-top: 20px;
	margin-right: 2rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.industry-summary-wrap {
	margin-bottom: 20px;
}

.top {
	height: 0px;
}

.back-to-top-not-working {
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 20000;
	display: block;
	width: 45px;
	height: 45px;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding-top: 1.5rem;
	padding-right: 1rem;
	padding-left: 1rem;
	border-radius: 50px;
	background-color: hsla(0, 0%, 100%, 0.4);
	background-image: url('../images/chevron-up.svg'), -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-image: url('../images/chevron-up.svg'), linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-position: 50% 50%, 0px 0px;
	background-size: 20px 20px, auto;
	background-repeat: no-repeat, repeat;
	opacity: 0;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.fake-wysiwyg-image {
	width: 100%;
}

.inside-page-content-section {
	background-color: transparent;
}

.inside-page-container {
	max-width: 1500px;
	padding-right: 4vw;
	padding-bottom: 4rem;
	padding-left: 4vw;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.inside-page-container.landing-container {
	padding-right: 8vw;
	padding-bottom: 3rem;
	padding-left: 8vw;
}

.section-title {
	margin-top: 0rem;
	margin-bottom: 6px;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
}

.back-to-top {
	position: absolute;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 20px;
	padding: 15px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 50px;
	border-top-right-radius: 50px;
	background-color: #000;
	opacity: 0.85;
	-webkit-transition-property: all;
	transition-property: all;
	-webkit-transition-duration: 300ms;
	transition-duration: 300ms;
	text-decoration: none;
}

.back-to-top:hover {
	padding-bottom: 1.75rem;
	opacity: 1;
}

.textlink {
	border-bottom: 1px solid #ce3e16;
	color: #242425;
	font-weight: 400;
	text-decoration: none;
}

.textlink:hover {
	border-bottom-color: transparent;
	color: #ce3e16;
}

.new-slider {
	height: 575px;
	background-color: transparent;
}

.new-slider-arrow-link {
	display: block;
	margin-right: -2%;
	margin-left: -2%;
	opacity: 0.3;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	color: #595d63;
}

.new-slider-arrow-link:hover {
	opacity: 1;
}

.new-slider-nav {
	font-size: 0.8rem;
}

.new-slider-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding: 3.5rem 3vw 2.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.right-side-col {
	width: 20%;
	max-width: 250px;
	min-width: 170px;
	margin-top: -0.65rem;
	margin-left: 3vw;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 0.85rem;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 600;
}

.right-side-col a {
	border-bottom: 1px solid #ce3e16;
	color: #242425;
	font-weight: 400;
	text-decoration: none;
}

.right-side-col a:hover {
	border-bottom-color: transparent;
	color: #ce3e16;
}

.right-side-col ul {
	font-size: 0.95rem;
	line-height: 1.55rem;
}

.right-side-col p {
	font-size: 0.95rem;
	line-height: 1.55rem;
	letter-spacing: 0rem;
}

.right-side-col h3 {
	font-size: 1.125rem;
	line-height: 1.375rem;
}

.right-side-col h4 {
	color: #616163;
	line-height: 1.375rem;
	text-transform: none;
}

.right-col-item-wrap {
	margin-bottom: 1rem;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid #d6d2c6;
}

.right-col-list-with-bullets {
	padding-left: 20px;
	font-size: 0.85rem;
	line-height: 1.4rem;
}

.styles-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.header-link {
	border-bottom: 1px solid #ce3e16;
	color: #616163;
	text-decoration: none;
}

.header-link:hover {
	border-bottom-color: transparent;
	color: #ce3e16;
}

.h4-h6-link {
	border-bottom: 1px solid #a6b0c0;
	color: #ce3e16;
	font-weight: 600;
	text-decoration: none;
}

.h4-h6-link:hover {
	border-bottom-color: transparent;
	color: #616163;
}

.styles-flex-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 32px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #17191d;
	font-size: 1.1rem;
}

.screen-message.success {
	background-color: rgba(17, 185, 30, 0.25);
}

.screen-message.error {
	background-color: rgba(253, 54, 54, 0.15);
}

.screen-message-text {
	font-weight: 500;
}

.highlight {
	background-color: #f8ff5a;
}

.horizontal-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #a6b0c0;
}

.columns {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.footer-grip-link-2 {
	width: 130px;
	height: 28px;
	min-width: 0px;
	padding-left: 32px;
	float: right;
	background-image: url('../images/globalreach_knockout_fullwhite.png');
	background-position: 50% 50%;
	background-size: 130px;
	background-repeat: no-repeat;
	opacity: 0.3;
	text-decoration: none;
}

.footer-grip-link-2:hover {
	opacity: 1;
}

.grip-logo-text-box-2 {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 8px;
	line-height: 8px;
	text-decoration: none;
	text-transform: uppercase;
}

.section-3-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1200px;
	min-height: 375px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.section-3 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 375px;
	padding-top: 1rem;
	padding-right: 4vw;
	padding-left: 4vw;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: #e3e7ee;
}

.footer-left-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.two-part-mobile-nav-wrapper-2 {
	display: none;
}

.mobile-menu-arrow-2 {
	display: none;
}

.note {
	display: inline-block;
	margin-left: 25px;
	background-color: #ffff2c;
	color: #242425;
}

.plugin-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1.25rem;
	margin-bottom: 2rem;
	padding-bottom: 3.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid #c7c7c7;
}

.plugin-row._3-box-row {
	margin-top: 0rem;
}

.plugin-cb {
	width: 47%;
}

.plugin-cb.single {
	width: 100%;
	padding-right: 9vw;
	padding-left: 9vw;
	text-align: center;
}

.plugin-cb.triple {
	width: 30%;
}

.plugin-landing-hero {
	position: relative;
	height: 420px;
	background-color: #e6ebf5;
	background-image: url('../images/random-grey-variations-wht50.png');
	background-size: 100px;
	background-repeat: repeat;
	box-shadow: inset 0 5px 9px 0 rgba(0, 0, 0, 0.07);
}

.megamenu-parent {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.megamenu-child {
	min-width: 200px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
}

.body {
	color: #616163;
}

.left-nav-item-wrap {
	border-top: 1px solid hsla(219, 9%, 69%, 0.5);
}

.left-nav-item-wrap.first-subpage {
	border-top-style: none;
}

.left-nav-item-wrap.sub-wrap {
	border-top-style: none;
}

.left-nav-item-wrap.open-left-nav-child {
	box-shadow: inset 0 -1px 0 0 hsla(219, 9%, 69%, 0.5);
}

.inner-content {
	position: relative;
	top: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

@media all and (-ms-high-contrast: none) {
	.inside-page-nested-row.landing-parent {
		display: block;
	}
}

@media all and (-ms-high-contrast: none) and (max-width: 991px) {
	.plugin-row:not(._3-box-row) {
		display: block;
	}

	.portfolio-text-wrapper {
		min-width: auto;
	}
}

.inside-page-banner.two {
	height: 215px;
	background-color: transparent;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	display: flex;
	justify-content: center;
	background-repeat: no-repeat;
}

.banner-text {
	max-width: 475px;
	padding: 12px 1rem;
	border-radius: 0px;
	background-color: transparent;
	color: #fff;
	font-size: 2.375rem;
	line-height: 2.5rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.banner-text h1 {
	margin: 0px;
	padding: 0px;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.625rem;
		margin-top: 1rem;
	}

	h2.page-title {
		font-size: 2.25rem;
		line-height: 2.625rem;
		margin-top: 1rem;
	}

	.section-3-header {
		margin-top: 1rem;
	}

	.main-nav-dd-toggle {
		display: block;
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 40px;
		background-color: transparent;
		box-shadow: inset 0 -1px 0 0 rgba(97, 97, 99, 0.5);
		-webkit-transition-property: none;
		transition-property: none;
		color: #fff;
	}

	.main-nav-dd-toggle:hover {
		background-color: #17191d;
		box-shadow: none;
		color: #fff;
	}

	.main-nav-dd-toggle.w--current {
		background-color: #17191d;
		box-shadow: inset 0 -1px 0 0 rgba(97, 97, 99, 0.5);
		color: #fff;
	}

	.main-nav-dd-toggle.mobile-duplicate {
		width: 88%;
		text-align: left;
	}

	.secondary-nav-wrap {
		display: none;
		margin-right: 15px;
		line-height: 16px;
	}

	.secondary-nav-list {
		display: block;
		margin-bottom: 2px;
	}

	.nav-menu {
		width: 80%;
		margin-top: 0px;
		padding-top: 0px;
		background-color: #2d3037;
	}

	.logo-home-link {
		width: 225px;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.section-1.portfolio-section {
		height: auto;
		background-position: 0px 0px, 0px 0px, 0px 0px;
	}

	.section-1.cta-section {
		display: block;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.section-1.cta-section-plugins {
		display: block;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrapper {
		padding-top: 76px;
	}

	.main-content-wrapper.inside-main {
		padding-top: 76px;
	}

	.section-2 {
		display: block;
		padding-right: 0vw;
		padding-bottom: 0px;
		padding-left: 0vw;
	}

	.footer-section {
		background-size: cover;
		background-repeat: no-repeat;
	}

	.footer-paragraph {
		line-height: 24px;
	}

	.footer-list-link {
		line-height: 24px;
		letter-spacing: 0.02rem;
	}

	.social-media-wrapper {
		margin-right: 5px;
	}

	.signoff-text {
		padding-right: 0px;
		padding-left: 5px;
	}

	.grip-footer-lockup {
		float: left;
	}

	.footer-column-3 {
		width: 40%;
		padding-right: 0px;
		padding-bottom: 32px;
	}

	.slider-image-div {
		width: 320px;
	}

	.slider-image-div.slide-1 {
		width: 43.5%;
	}

	.slider-header {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	.slider-paragraph {
		display: block;
		margin-top: 1rem;
		font-size: 1.125rem;
		line-height: 1.625rem;
	}

	.slider-cta-link {
		font-size: 0.95rem;
		line-height: 1rem;
	}

	.cta-bar-header {
		display: inline-block;
		font-size: 1.35rem;
		line-height: 2.35rem;
		text-align: left;
	}

	.button.dark-button {
		max-width: 300px;
		float: none;
	}

	.special-button-wrapper {
		margin-left: 1rem;
	}

	.section-2-header {
		font-size: 2.375rem;
		line-height: 3rem;
	}

	.section-2-subhead {
		text-transform: none;
	}

	.section-2-paragraph {
		min-width: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
		text-align: center;
	}

	.section-2-column {
		width: 50%;
		margin-bottom: 0px;
		padding-right: 9vw;
		padding-bottom: 40px;
		padding-left: 9vw;
	}

	.section-2-column.col-1-and-2 {
		margin-bottom: 60px;
		padding-right: 5vw;
		padding-bottom: 30px;
		padding-left: 5vw;
	}

	.section-2-column.col-2-and-3 {
		margin-bottom: 60px;
	}

	.section-2-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		margin-bottom: 0rem;
		padding-right: 4vw;
		padding-bottom: 0rem;
		padding-left: 4vw;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-style: none;
	}

	.section-2-row.row-2-last {
		padding-bottom: 2rem;
	}

	.section-3-paragraph {
		max-width: none;
	}

	.section-3-image-wrapper {
		padding-top: 1rem;
	}

	.section-3-column-2 {
		width: 100%;
		padding-right: 10px;
		padding-left: 10px;
	}

	.footer-row-1 {
		margin-bottom: 32px;
	}

	.section-3-column-1 {
		width: 100%;
		height: auto;
		min-height: 0px;
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		display: block;
	}

	.mobile-menu-button {
		margin-top: 13px;
		padding: 6px 6px 3px;
	}

	.mobile-menu-button:hover {
		color: #ce3e16;
	}

	.mobile-menu-button.w--open {
		background-color: transparent;
		color: #ce3e16;
	}

	.menu-icon {
		float: right;
		font-size: 42px;
		line-height: 35px;
	}

	.menu-text {
		display: none;
		padding-top: 4px;
		float: right;
		font-size: 16px;
	}

	.cta-row {
		padding-right: 10px;
		padding-left: 10px;
		text-align: left;
	}

	.cta-row.portfolio-row {
		padding-top: 40px;
		padding-bottom: 60px;
		text-align: center;
	}

	.section-1-container.portfolio-container {
		padding-top: 0px;
	}

	.cta-column-1.portfolio-cta-col {
		display: block;
		height: auto;
	}

	.cta-column-2.portfolio-column-2 {
		height: auto;
		text-align: center;
	}

	.section-2-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.footer-container {
		padding-right: 4vw;
		padding-left: 4vw;
	}

	.learn-moretext-link-wrapper {
		text-align: center;
	}

	.footer-column-1 {
		padding-left: 0px;
	}

	.footer-column-2 {
		margin-top: 10px;
	}

	.footer-row-2-column-1 {
		margin-bottom: 0.75rem;
		padding-right: 5px;
	}

	.section-2-header-wrapper {
		margin-bottom: 3rem;
	}

	.inside-page-left-column {
		left: 0px;
		display: none;
	}

	.inside-page-content-column {
		width: 100%;
		padding-bottom: 3rem;
	}

	.left-nav-list-item {
		margin-bottom: 16px;
	}

	.left-nav-link {
		font-size: 14px;
		line-height: 16px;
	}

	.inside-page-nested-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-page-nested-row.landing-parent {
		padding-top: 0rem;
	}

	.portfolio-cta-paragraph {
		position: static;
		left: 0px;
		margin-top: 10px;
		float: none;
		font-size: 20px;
	}

	.portfolio-cta-header {
		font-size: 36px;
		line-height: 42px;
	}

	.demo-wrapper {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.slider-container {
		max-width: 768px;
		min-width: 0px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.module-icon-wrap {
		width: 125px;
		height: 125px;
	}

	.container-2 {
		padding-right: 4vw;
		padding-left: 4vw;
	}

	.slide-text-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 56.5%;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.landing-page-hero {
		height: 380px;
	}

	.xl-intro-paragraph {
		font-size: 20px;
		line-height: 32px;
	}

	.landing-hero-icon {
		margin-right: 0rem;
	}

	.landing-main-icon-wrap {
		width: 180px;
		height: 180px;
		margin-right: 0rem;
	}

	.landing-hero-header {
		font-size: 40px;
		line-height: 44px;
	}

	.inside-page-container {
		padding-right: 2.5rem;
		padding-bottom: 3rem;
		padding-left: 2.5rem;
		padding-top: 1rem;
	}

	.inside-page-container.landing-container {
		padding-bottom: 0rem;
	}

	.new-slider {
		height: 400px;
	}

	.new-slider-arrow-link {
		display: none;
	}

	.new-slider-content-wrap {
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding: 1rem 0rem 0rem;
		border-top: 1px solid hsla(0, 0%, 78%, 0.5);
		background-color: transparent;
	}

	.styles-flex {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.columns {
		margin-top: 51px;
	}

	.footer-grip-link-2 {
		margin-top: 1rem;
	}

	.section-3-container {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.section-3 {
		display: block;
	}

	.footer-left-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.two-part-mobile-nav-wrapper-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.mobile-link-label {
		display: inline-block;
		margin-top: 0rem;
		margin-bottom: 0rem;
		line-height: 1.2rem;
	}

	.mobile-menu-arrow-2 {
		display: block;
		width: 15%;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		background-image: url('../images/chevron-right_white.svg');
		background-position: 50% 50%;
		background-size: 12px 24px;
		background-repeat: no-repeat;
		color: #000;
	}

	.mobile-menu-arrow-2:hover {
		background-color: #17191d;
		opacity: 1;
	}

	.plugin-row {
		margin-top: 0rem;
		margin-bottom: 1rem;
		padding-bottom: 1.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.plugin-row._3-box-row {
		margin-bottom: 2rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.plugin-row.even {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.plugin-row.odd {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.plugin-cb {
		width: 100%;
		margin-bottom: 2rem;
	}

	.plugin-landing-hero {
		height: 380px;
	}

	.inside-page-banner.two {
		height: 200px;
	}

	.banner-text {
		max-width: 350px;
		font-size: 2rem;
		line-height: 2.25rem;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 1.875rem;
		line-height: 2.25rem;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	h2.page-title {
		font-size: 1.875rem;
		line-height: 2.25rem;
	}

	h4 {
		line-height: 1.5rem;
	}

	.main-nav-dd-toggle {
		padding-left: 40px;
	}

	.logo-home-link {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.section-1 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100px;
		padding-top: 10px;
		padding-bottom: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.section-1.portfolio-section {
		height: auto;
		padding-top: 20px;
		padding-bottom: 20px;
		overflow: hidden;
	}

	.section-1.cta-section {
		height: auto;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.section-1.cta-section-plugins {
		height: auto;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.main-content-wrapper.inside-main {
		padding-top: 76px;
	}

	.section-2 {
		padding-bottom: 0px;
	}

	.footer-section {
		background-color: #272a30;
		background-image: none;
	}

	.map-pin-wrapper {
		margin-bottom: 50px;
	}

	.vertical-pipe {
		display: none;
	}

	.social-media-wrapper {
		margin-bottom: 20px;
		float: none;
		text-align: center;
		width: auto;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.social-media-link-block {
		margin-right: 10px;
		margin-left: 10px;
		display: inline-block;
	}

	.signoff-text {
		float: none;
		text-align: center;
	}

	.grip-footer-lockup {
		float: none;
	}

	.footer-column-3 {
		width: 100%;
		padding-bottom: 0px;
		padding-left: 0px;
		border-left: 1px none #000;
	}

	.slider-image-div {
		top: 20px;
		right: 0px;
		width: 260px;
	}

	.slider-header {
		padding-top: 1px;
		padding-right: 10px;
		padding-left: 8px;
		font-size: 26px;
		line-height: 32px;
	}

	.slider-header-emphasis {
		font-size: 26px;
	}

	.slider-paragraph {
		font-size: 14px;
		line-height: 20px;
	}

	.slider-cta-link {
		position: static;
		margin-top: 10%;
		padding-left: 26px;
		background-size: 18px 18px;
		font-size: 14px;
	}

	.cta-bar-header {
		margin-bottom: 1.25rem;
		float: none;
		line-height: 2rem;
		text-align: left;
	}

	.special-button-wrapper {
		display: block;
		margin-left: 0rem;
		float: none;
	}

	.section-2-cb-heading {
		line-height: 2.375rem;
	}

	.section-2-paragraph {
		display: block;
		font-size: 0.95rem;
		line-height: 1.55rem;
		text-align: center;
	}

	.section-2-column {
		width: 100%;
	}

	.section-2-column.col-1-and-2 {
		margin-bottom: 50px;
		padding-bottom: 30px;
	}

	.section-2-column.col-2-and-3 {
		margin-bottom: 50px;
	}

	.section-3-paragraph {
		display: block;
		width: 100%;
		max-width: none;
		margin-right: auto;
		margin-left: auto;
		padding-left: 0px;
		text-align: center;
	}

	.section-3-image-wrapper {
		padding-right: 10px;
		padding-left: 10px;
	}

	.section-3-column-2 {
		display: block;
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-row-1 {
		padding-right: 34px;
		padding-left: 34px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		display: block;
	}

	.section-3-column-1 {
		padding-bottom: 24px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.menu-text {
		display: none;
	}

	.cta-row {
		padding-top: 45px;
		padding-bottom: 60px;
	}

	.section-1-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cta-column-1 {
		display: block;
	}

	.cta-column-1.portfolio-cta-col {
		margin-top: 0px;
	}

	.footer-column-1 {
		padding-left: 0px;
	}

	.footer-column-2 {
		margin-bottom: 28px;
		padding-bottom: 16px;
		border-bottom: 1px solid #595959;
		text-align: left;
	}

	.footer-nested-column {
		padding-left: 0px;
	}

	.inside-page-left-column {
		display: none;
	}

	.inside-page-content-column {
		padding-bottom: 3rem;
		box-shadow: none;
	}

	.breadcrumbs-list {
		line-height: 1.3rem;
	}

	.intro-paragraph {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.portfolio-cta-header {
		font-size: 30px;
		line-height: 36px;
	}

	.demo-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
	}

	.landing-page-hero {
		height: 280px;
	}

	.xl-intro-paragraph {
		font-size: 18px;
		line-height: 28px;
	}

	.landing-main-icon-wrap {
		width: 145px;
		height: 145px;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.inside-page-container {
		padding-right: 2rem;
		padding-bottom: 2rem;
		padding-left: 2rem;
	}

	.back-to-top {
		width: 60px;
		height: 60px;
	}

	.new-slider {
		height: 280px;
	}

	.right-side-col {
		padding-top: 0.5rem;
	}

	.columns {
		margin-top: 0px;
	}

	.footer-grip-link-2 {
		display: block;
		margin-top: 2rem;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.footer-left-wrap {
		width: 100%;
	}

	.mobile-menu-arrow-2 {
		background-size: 16px 16px;
	}

	.mobile-menu-arrow-2:hover {
		background-size: 16px 16px;
	}

	.plugin-row._3-box-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.plugin-cb.single {
		padding-right: 5vw;
		padding-left: 5vw;
	}

	.plugin-cb.triple {
		width: 100%;
		margin-bottom: 0.5rem;
	}

	.plugin-landing-hero {
		height: 280px;
	}

	.inside-page-banner.two {
		height: 175px;
		padding: 20px;
	}

	.banner-text {
		font-size: 2rem;
		line-height: 2.15rem;
	}
}

@media (max-width: 479px) {
	.main-nav-dd-toggle {
		padding-left: 20px;
	}

	.logo-home-link {
		width: 170px;
	}

	.section-1.portfolio-section {
		height: auto;
	}

	.main-content-wrapper {
		padding-top: 62px;
	}

	.main-content-wrapper.inside-main {
		padding-top: 62px;
	}

	.section-2 {
		display: block;
	}

	.footer-section {
		background-size: cover;
	}

	.map-pin-wrapper {
		margin-right: 7px;
		margin-bottom: 190px;
	}

	.footer-paragraph {
		display: none;
		float: none;
	}

	.vertical-pipe {
		display: none;
	}

	.social-media-wrapper {
		margin-top: 10px;
		margin-right: 0px;
		margin-bottom: 30px;
		float: none;
		text-align: center;
	}

	.social-media-link-block {
		margin-right: 10px;
		margin-left: 10px;
	}

	.signoff-text {
		float: none;
		text-align: center;
	}

	.grip-footer-lockup {
		float: none;
	}

	.footer-column-3 {
		border-style: none;
	}

	.slider-image-div {
		position: static;
		display: block;
		width: 240px;
		margin-right: auto;
		margin-left: auto;
	}

	.slider-image-div.slide-1 {
		width: 100%;
		height: auto;
		min-height: 250px;
	}

	.slider-header {
		margin-bottom: 1px;
		font-size: 28px;
		line-height: 32px;
	}

	.slider-header-emphasis {
		font-size: 28px;
	}

	.slider-cta-link {
		position: static;
		bottom: 0px;
		margin-top: 2%;
	}

	.cta-bar-header {
		text-align: center;
	}

	.button.no-margin {
		margin-right: 0rem;
	}

	.special-button-wrapper {
		right: auto;
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.section-2-subhead {
		font-size: 1rem;
	}

	.section-2-paragraph {
		width: 100%;
	}

	.section-2-row.row-2-last {
		padding-bottom: 1rem;
	}

	.section-3-header {
		margin-top: 10px;
		margin-bottom: 15px;
		font-size: 26px;
		line-height: 32px;
		text-align: center;
	}

	.section-3-paragraph {
		width: 90%;
		font-size: 1rem;
	}

	.section-3-column-2 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-row-1 {
		padding-right: 15px;
		padding-left: 15px;
	}

	.section-3-column-1 {
		padding-bottom: 16px;
	}

	.header-container {
		padding-right: 0.5rem;
		padding-left: 1rem;
	}

	.mobile-menu-button {
		margin-top: 0px;
		padding-top: 13px;
		padding-bottom: 9px;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.menu-text {
		display: none;
	}

	.cta-row {
		padding-top: 35px;
		padding-bottom: 55px;
	}

	.cta-column-1 {
		display: none;
	}

	.section-2-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.footer-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.footer-column-2 {
		margin-bottom: 0px;
		border-bottom: 1px none #000;
	}

	.footer-row-2-column-1 {
		position: static;
	}

	.footer-nested-column {
		padding-top: 25px;
		border-top: 1px solid #595959;
	}

	.footer-nested-column.footer-nest-col-2 {
		padding-right: 0px;
	}

	.breadcrumbs-list {
		line-height: 1.3rem;
	}

	.portfolio-cta-paragraph {
		color: #000;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
	}

	.portfolio-text-wrapper {
		min-width: 0px;
	}

	.demo-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.plugin-wrap,
	.industry-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.module-icon-wrap {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.slide-text-wrap {
		width: 100%;
		margin-bottom: 1.5rem;
		padding-right: 0vw;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.centered {
		text-align: center;
	}

	.edition-icon-wrap {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.inside-page-content-section {
		display: block;
	}

	.inside-page-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		padding-top: 0.5rem;
	}

	.back-to-top {
		margin-right: 0px;
	}

	.new-slider {
		height: auto;
		padding-bottom: 2.5rem;
	}

	.new-slider-content-wrap {
		height: auto;
		padding: 2rem 0vw 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-grip-link-2 {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.section-3-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.section-3 {
		display: block;
	}

	.mobile-link-label {
		font-size: 0.9rem;
		line-height: 1.2rem;
	}

	.mobile-menu-arrow-2 {
		width: 20%;
	}

	.plugin-row._3-box-row {
		margin-bottom: 1rem;
	}

	.plugin-cb.single {
		margin-bottom: 1rem;
	}

	.heading {
		text-align: center;
	}

	.paragraph {
		text-align: left;
	}

	.industry-summary-wrap {
		text-align: center;
	}

	.inside-page-banner.two {
		height: 175px;
		padding-top: 60px;
		padding-bottom: 60px;
		background-position: 0px 0px, 50% 50%;
	}

	.inner-content {
		height: auto;
		text-align: center;
	}

	.banner-text {
		font-size: 1.75rem;
		line-height: 2.15rem;
		font-weight: 400;
	}
}

@media all and (-ms-high-contrast: none) and (max-width: 479px) {
	.demo-wrapper {
		display: block;
	}

	.special-button-wrapper {
		display: inline-block;
	}
}

/**************** Webflow Styles ********************/
/****************************************************/
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

/* CONTROL PHONE NUMBER COLOR */
a[href^=tel] {
	color: #9a9da4;
	text-decoration: none;
}
/* Mozilla based browsers */
::-moz-selection {
	background-color: #e6ebf5;
	color: inherit;
}
/* Safari browser */
::selection {
	background-color: #e6ebf5;
	color: inherit;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/

/********** ***** Accesible Menu **********************/
/*****************************************************/
.accesible-navigation-menu ul.nav-menu {
	margin-left: 0px;
	margin-right: 0px;
	padding: 0px;
	list-style-type: none;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle {
	display: inline-block;
	position: relative;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle a.main-nav-link.active {
	color: #ce3e16;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle a.main-nav-link {
	display: inline-block;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	min-width: 200px;
	left: 0;
	transition: opacity 300ms ease 0s, height 500ms ease 0s;
	height: 0;
	width: 100%;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle.open .container-wrapper {
	opacity: 1;
	max-height: 5000px;
	height: auto;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper ul {
	background-color: #e6ebf5;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	display: none;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle.open .container-wrapper ul {
	display: block;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper ul li {
	margin: 0px;
	padding: 0px;
	display: block;
	text-transform: none;
}


.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper ul li a {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid hsla(219, 9%, 69%, 0.5);
	color: #242425;
	font-size: 0.95rem;
	font-weight: 400;
	display: inline-block;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	text-decoration: none;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper ul li a:hover {
	background-color: #ccd1db;
	color: #242425;
}


/* mega menu */
.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle[data-megamenu='true']#menu-item-plugins .container-wrapper {
	left: -296px;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle[data-megamenu='true']#menu-item-industry-solutions .container-wrapper {
	left: -480px;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle[data-megamenu='true']#menu-item-about .container-wrapper {
	left: -300px;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle[data-megamenu='true']#menu-item-support .container-wrapper {
	left: -717px;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle[data-megamenu='false']#menu-item-support .container-wrapper {
	right: 0;
	left: auto;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle[data-megamenu='true'] .container-wrapper ul {
	width: calc(690px + 2rem);
	align-content: flex-start;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle[data-megamenu='true'].open .container-wrapper ul {
	display: flex;
	flex-wrap: wrap;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle[data-megamenu='true'] .container-wrapper ul li {
	width: 230px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
}

/****************** Solution Menu **********************/
/*****************************************************/
.solutions-navigation-menu {
	display: none;
	position: absolute;
	top: 95px;
	left: 0;
	background-color: #353840;
	width: 100%;
}

.solutions-navigation-menu select#solutions {
	padding: 0.75rem 1.6rem 0.75rem 0;
	color: #e6ebf5;
	font-size: 0.9rem;
	font-weight: 600;
	text-transform: uppercase;
	background-color: transparent;
	border: 0;
	width: 140px;
}

.solutions-navigation-menu select#solutions option {
	background-color: #f8992a;
	padding: 10px 20px;
	display: block;
	color: #353840;
}

.solutions-navigation-menu .solutions-container {
	position: relative;
	left: 264px;
}

.solutions-navigation-menu .solutions-container.panel-heading {
	padding: 0.75rem 2.6rem 0.75rem 0px;
	color: #e6ebf5;
	font-size: 0.9rem;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
}

.w-icon-arrow-down:before,
.w-icon-dropdown-toggle:before {
	content: "\e603";
}

.solutions-navigation-menu .w-dropdown-link {
	padding: 10px 20px;
	display: block;
	color: #222222;
}

.solutions-navigation-menu .fast-travel-link:hover {
	background-color: #e9c85e;
	color: #353840;
}

.solutions-navigation-menu .panel-collapse.collapse {
	display: none;
	position: absolute;
	top: 50px;
	left: 243px;
	background-color: #f8992a;
	color: #353840;
}

@media (max-width: 1530px) {
	.solutions-navigation-menu .solutions-container {
		left: 57px;
	}
}

/****************** Modules Menu **********************/
/*****************************************************/

.modules-submenu {
	width: 72%;
	margin: auto;
	display: flex;
}

.modules-submenu-scrollbar {
	white-space: nowrap;
	width: 100%;
	overflow-x: scroll;
	margin: 0 7px;
	padding: 0.75rem 2.6rem 0.75rem 0;
}

.scroll-button {
	width: 30px;
	background-color: transparent;
}

.modules-submenu-arrow {
	filter: invert(86%);
}

.modules-submenu-separator {
	color: #e6ebf5;
}

.modules-submenu-scrollbar::-webkit-scrollbar {
	display: none;
}

.modules-submenu-link {
	color: #e6ebf5;
	font-size: 0.9rem;
	text-decoration: none;
}

a.modules-submenu-link.w--current {
	color: #ce3e16;
}

@media (max-width: 1530px) {
	.modules-submenu {
		width: 90%;
	}
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background: #2d3037;
}

.mm-listitem,
.mm-listitem .mm-btn_next,
.mm-listitem .mm-btn_next:before {
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mm-listitem:after {
	left: 0;
}

.mobile-navigation-menu li a.w-nav-link {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
	transition-property: none;
	color: #fff;
	padding-left: 30px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.mobile-navigation-menu li a.w-nav-link:hover {
	background-color: #17191d;
	color: #fff;
}

.mobile-navigation-menu li.active a.w-nav-link {
	background-color: #353840 !important;
	color: #fff;
}

.mobile-navigation-menu li a.mm-btn_next {
	height: 48px;
}

.mobile-navigation-menu li a.mm-btn_next:hover {
	background-color: #17191d;
	color: #fff;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #fff;
}

.mm-navbar {
	text-align: left;
	height: 50px;
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
	transition-property: none;
	color: #fff;
}

.mm-menu .mm-navbar a {
	padding-top: 15px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -10px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
}

.mm-btn_prev:before {
	left: 18px;
	top: 10px;
}


/****************** Homepage **********************/
/*****************************************************/
.cta-bar-header {
	line-height: 3rem;
	letter-spacing: 0.04rem;
}

.cta-bar-header p {
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-size: inherit;
	letter-spacing: inherit;
}

.cta-bar-header p:last-child {
	margin-bottom: 0px;
}

.section-2-header a,
.section-2-header a:hover {
	text-decoration: none;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
	font-family: inherit;
}

.cta-bar-header strong {
	font-weight: 400;
}

.section-2-header {
	font-size: 2.375rem;
	line-height: 3rem;
	margin-bottom: 1.25rem;
}

.section-2-paragraph p,
.section-3-paragraph p {
	font-family: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-size: inherit;
	color: inherit;
	text-decoration: none;
}

.section-2-paragraph *,
.section-3-paragraph * {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
}

.portfolio-text-wrapper p {
	position: static;
	margin-top: 0px;
	float: right;
	color: #17191d;
	font-size: 1.375rem;
	line-height: 2.125rem;
	font-weight: 400;
}

.portfolio-text-wrapper h2 {
	margin-top: 0px;
	margin-bottom: 16px;
	color: #17191d;
	font-size: 2.375rem;
	line-height: 2.75rem;
	font-weight: 400;
}

.whitebold {
	color: #fff;
	font-weight: 700;
	background: none !important;
}

.section-2-subhead p {
	letter-spacing: 0.03rem;
}

.section-2-header.carousel {
	margin-top: 0px;
	margin-bottom: 2rem;
}

@media (max-width: 991px) {
	.cta-bar-header {
		line-height: 2.35rem;
	}

	.portfolio-text-wrapper p {
		position: static;
		left: 0px;
		margin-top: 10px;
		float: none;
		font-size: 20px;
	}

	.portfolio-text-wrapper h2 {
		font-size: 36px;
		line-height: 42px;
	}

	.portfolio-text-wrapper p {
		text-align: center !important;
	}
}

@media (max-width: 767px) {
	.cta-bar-header {
		line-height: 2rem;
	}

	.portfolio-text-wrapper h2 {
		font-size: 30px;
		line-height: 36px;
	}
}

@media (max-width: 479px) {
	.portfolio-text-wrapper p {
		color: #000;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
	}
}

/****************** Slideshow **********************/
/*****************************************************/
.slider-paragraph {
	margin: 0px;
	max-width: 452px;
}

.slider-paragraph p {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	clear: left;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	margin-left: 11px;
	display: inline-block;
}

.slider-paragraph h2,
.slider-paragraph h1,
.slider-paragraph h3,
.slider-paragraph h4,
.slider-paragraph h5 {
	display: inline-block;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 2px 12px 4px 10px;
	clear: left;
	background-color: #353840;
	color: #fff;
	font-size: 2.75rem;
	line-height: 3rem;
	font-weight: 300;
	text-transform: uppercase;
}

.slider-paragraph h2 strong,
.slider-paragraph h1 strong,
.slider-paragraph h3 strong,
.slider-paragraph h4 strong,
.slider-paragraph h5 strong {
	font-weight: 700;
}

@media all and (-ms-high-contrast: none) {
	.slide-text-wrap {
		display: block !important;
	}
}

@media (max-width: 991px) {

	.slider-paragraph p {
		margin-top: 1rem;
	}


	.slider-paragraph h2,
	.slider-paragraph h1,
	.slider-paragraph h3,
	.slider-paragraph h4,
	.slider-paragraph h5 {
		font-size: 2rem;
		line-height: 2.25rem;
	}
}

@media (max-width: 767px) {
	.slider-paragraph h2,
	.slider-paragraph h1,
	.slider-paragraph h3,
	.slider-paragraph h4,
	.slider-paragraph h5 {
		padding-top: 1px;
		padding-right: 10px;
		padding-left: 8px;
		font-size: 26px;
		line-height: 32px;
	}

	.slider-paragraph p,
	.slider-paragraph li,
	.slider-paragraph ul,
	.slider-paragraph table {
		display: none;
	}
}


@media (max-width: 479px) {
	.slider-paragraph {
		text-align: center;
	}

	.slider-paragraph h2,
	.slider-paragraph h1,
	.slider-paragraph h3,
	.slider-paragraph h4,
	.slider-paragraph h5 {
		margin-bottom: 1px;
		font-size: 28px;
		line-height: 32px;
	}

	.new-slider {
		padding-bottom: 2rem;
	}
}


/****************** Landing Pages **********************/
/*****************************************************/
.inside-page-row .cb-title a,
.inside-page-row .cb-title a:hover {
	border-bottom: 0px;
	color: inherit !important;
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
}

.plugin-cb.single p {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-size: 1.75rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: normal;
}

.plugin-cb.single ul {
	list-style-type: none;
	font-size: 1.75rem;
	line-height: 2.375rem;
}

@media (max-width: 1032px) and (min-width: 992px) {
	.cta-section-plugins .button.special-button {
		padding: 10px 65px 0px 6px;
		font-size: 1rem;
	}
}


@media (max-width: 767px) {
	.plugin-cb.single p {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.plugin-cb.single ul {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

/****************** Inside Page *******************/
/*************************************************/
td img {
	max-width: none;
}

.section-title {
	padding: 8px 4px 8px 12px;
}

.inside-page-row .section-title a:hover,
.inside-page-row .section-title a {
	color: #242425 !important;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 600 !important;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: inherit;
	border-bottom: 0px;
}

.left-nav-list li {
	border-top: 1px solid hsla(219, 9%, 69%, 0.5);
}

.left-nav-list li li {
	border: 0px;
}

.left-nav-list ul {
	border-top: 1px solid hsla(219, 9%, 69%, 0.5);
	margin-left: 0px !important;
}

.left-nav-list ul li {
	margin-left: 0.75rem;
}

.left-nav-list ul ul {
	border: 0px;
}

.left-nav-list ul li li {
	margin-left: 1rem;
}

.left-nav-list li a.active,
.left-nav-list li a.active:hover,
.left-nav-list li a.selected,
.left-nav-list li a.selected:hover {
	background-color: rgba(227, 231, 238, 0.5);
	color: #17191d !important;
	transform: none;
}

.left-nav-list li li li a.active,
.left-nav-list li li li a.active:hover,
.left-nav-list li li li a.selected,
.left-nav-list li li li a.selected:hover {
	background-color: transparent;
	color: #ce3e16 !important;
	font-weight: 600;
}

.inside-page-row .left-nav-list a,
.inside-page-row .breadcrumbs-list a {
	border-bottom: 0px;
}

.breadcrumbs-list .divider {
	display: inline;
}

.breadcrumbs-list li:last-child a {
	color: #ce3e16;
	font-weight: 700;
}

.breadcrumbs-list li:last-child .divider {
	display: none;
}

.introparagraph {
	font-family: 'Open Sans', sans-serif;
	color: #616163;
	font-size: 1.25rem;
	line-height: 1.875rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
	margin-bottom: 0.75rem;
	margin-top: 0;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}

	.introparagraph {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

/******************* Right Sidebar *******************/
/*****************************************************/
.right-side-col .box,
.left-nav-col .box {
	margin-bottom: 1rem;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid hsla(0, 0%, 78%, 0.5);
	font-size: 0.95rem;
	line-height: 1.55rem;
	letter-spacing: 0rem;
}

.right-side-col .box *,
.left-nav-col .box * {
	font-size: 0.95rem;
	line-height: 1.55rem;
	letter-spacing: 0rem;
}

.right-side-col .box h4,
.right-side-col .box h3,
.left-nav-col .box h4,
.left-nav-col .box h3 {
	margin-top: 0.85rem;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
}

.right-side-col .box p,
.left-nav-col .box p {
	font-size: 0.95rem;
	line-height: 1.55rem;
	letter-spacing: 0rem;
}

.right-side-col .box ul,
.left-nav-col .box ul {
	font-size: 0.95rem;
	line-height: 1.55rem;
	letter-spacing: 0rem;
}


.right-side-col .box td,
.left-nav-col .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

.inside-page-nested-content-column {
	flex: 1;
}

.inside-page-container {
	min-height: 700px;
}

/*********************** Footer **********************/
/*****************************************************/
.phone-link.mobile {
	display: none;
}

.phone-link.desktop {
	display: inline-block;
}

#GRIPFooterLogo {
	opacity: 0.45;
	line-height: 12px;
	float: right;
	padding-top: 0px !important;
}

#GRIPFooterLogo:hover {
	opacity: 1;
}

.footer-list-link.active {
	color: #fff;
}

@media (max-width: 991px) {
	.phone-link.mobile {
		display: inline-block;
	}

	.phone-link.desktop {
		display: none;
	}

	#GRIPFooterLogo {
		margin-top: 1rem;
		float: none;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin: 0 auto;
		margin-top: 2rem;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.inside-page-row a,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	border-bottom: 1px solid #ce3e16;
	color: #000;
	font-weight: 400;
	text-decoration: none;
}

.inside-page-row a:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	border-bottom-color: transparent;
	color: #ce3e16;
}

.right-sidebar-box a {
	color: #242425;
}

.right-sidebar-box a:hover {
	border-bottom-color: transparent;
	color: #ce3e16;
}

.inside-page-row h1 a,
.inside-page-row h2 a,
.inside-page-row h3 a,
.inside-page-row h5 a,
.inside-page-row h4 a,
.inside-page-row h6 a {
	border-bottom: 1px solid #ce3e16;
	color: #616163 !important;
	text-decoration: none;
	color: inherit;
	font-family: inherit;
	line-height: inherit;
	font-weight: inherit;
}

.inside-page-row h1 a:hover,
.inside-page-row h2 a:hover,
.inside-page-row h3 a:hover,
.inside-page-row h5 a:hover,
.inside-page-row h4 a:hover,
.inside-page-row h6 a:hover {
	border-bottom-color: transparent;
	color: #ce3e16 !important;
}

/* buttons */
.inside-body-section .button,
.primary,
.inside-body-section button[type="submit"],
.inside-body-section .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type=submit],
.button {
	text-align: center;
	border: 0px;
	border-bottom: 0px !important;
	display: inline-block;
	padding: 12px 24px 11px;
	background-color: #ce3e16;
	box-shadow: 0 3px 0 0 #997449;
	transition: all 200ms ease;
	color: #fff !important;
	font-size: 0.875rem;
	font-weight: 700 !important;
	text-align: center;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
	padding-bottom: 12px;
	border-radius: 100px;
	box-shadow: none;
	line-height: 0.875rem;
}

.button a {
	color: #fff !important;
	border-bottom: 0px !important;
}

.primary:hover,
.inside-body-section button[type="submit"]:hover,
.inside-body-section .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type=submit]:hover,
.button:hover {
	background-color: #ce3e16;
	text-shadow: none;
}

.button a:hover {
	background-color: #ce3e16;
	text-shadow: none;
}

.secondary,
.inside-body-section button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-body-section .cms_form_button.secondary,
.inside-body-section .button.secondary {
	text-align: center;
	border: 0px;
	border-bottom: 0px !important;
	display: inline-block;
	padding: 12px 24px 11px;
	background-color: #353840;
	box-shadow: 0 3px 0 0 #997449;
	transition: all 200ms ease;
	color: #fff !important;
	font-size: 0.875rem;
	font-weight: 700 !important;
	text-align: center;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
	padding-bottom: 12px;
	border-radius: 100px;
	box-shadow: none;
	line-height: 0.875rem;
}

.secondary:hover,
.inside-body-section button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-body-section .cms_form_button.secondary:hover,
.inside-body-section .button.secondary:hover {
	background-color: #24272e;
	text-shadow: none;
}

.tertiary,
.inside-body-section button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	text-align: center;
	border: 0px;
	border-bottom: 0px !important;
	display: inline-block;
	padding: 12px 24px 11px;
	background-color: #aaafb8;
	box-shadow: 0 3px 0 0 #997449;
	transition: all 200ms ease;
	color: #fff !important;
	font-size: 0.875rem;
	font-weight: 700 !important;
	text-align: center;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
	padding-bottom: 12px;
	border-radius: 100px;
	box-shadow: none;
	line-height: 0.875rem;
}

.tertiary:hover,
.inside-body-section button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #79808b;
	text-shadow: none;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #17191d;
	font-size: 1.1rem;
	background-color: rgba(17, 185, 30, 0.25);
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #17191d;
	font-size: 1.1rem;
	background-color: rgba(253, 54, 54, 0.15);
}

#message.success p,
#message.error p {
	color: #17191d;
}

#message.success *,
#message.error * {
	font-weight: 500;
	font-size: 1.1rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid whitesmoke;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #353840;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #aaafb8;
	color: #17191d;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: none;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
	transition: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
	color: #17191d;
}

table.styled.striped tr:nth-child(even) {
	background: #e6ebf5;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="file"],
select,
textarea {
	max-width: 100%;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
}

textarea {
	resize: vertical;
}

label {
	font-size: 0.95rem;
	font-weight: 600;
}

.cms_label {
	font-size: 0.95rem;
	font-weight: 600;
}

@media (max-width: 991px) {
	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	input[type="search"],
	input[type="file"],
	select,
	textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

@media (max-width: 767px) {
	.pagination-wrapper #pagination td {
		display: inline-block;
		width: 100%;
		text-align: center;
		padding: 3px 0px;
	}
}

/* date picker */
#ui-datepicker-div a {
	border-bottom: 0px;
}

/* hr */
hr:not(.fc-divider):not(.formBreak) {
	border-color: #e9ece3;
	border: 0px;
	color: #e9ece3;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}


/******************** Modules **********************/
/***************************************************/
/* Search */
@media (max-width: 991px) {
	.module-search input[type="search"] {
		margin-bottom: 15px;
	}
}


/* Accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 256px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #a6b0c0;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #ce3e16;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

#account_module.entity.dashboard ul li {
	margin-bottom: 8px;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}



/* homepage */
.portfolio-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-width: 560px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.button-3 {
	position: relative;
	display: inline-block;
	height: 38px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 12px 24px 11px;
	background-color: #ce3e16;
	box-shadow: 0 3px 0 0 #997449;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button-3.no-margin {
	margin-right: 0rem;
	padding-bottom: 12px;
	border-radius: 100px;
	box-shadow: none;
	line-height: 0.875rem;
}

.button-3.dark-button {
	width: auto;
	max-width: 460px;
	margin-right: 0rem;
	padding-bottom: 12px;
	float: right;
	border-radius: 100px;
	background-color: #353840;
	box-shadow: none;
	line-height: 0.875rem;
}

.portfolio-cta-paragraph {
	position: static;
	margin-top: 0px;
	float: right;
	color: #242425;
	font-size: 1.375rem;
	line-height: 2.125rem;
	font-weight: 400;
	text-align: center;
}

.portfolio-text-wrapper h2 {
	margin-top: 0px;
	margin-bottom: 5px;
	color: #242425;
	font-size: 2.375rem;
	line-height: 2.75rem;
	font-weight: 400;
}

.portfolio-text-wrapper p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	float: none;
}

.portfolio-text-wrapper p:last-child {
	margin-bottom: 0px;
}

.section-1-container.portfolio-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1200px;
	padding-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.section-1.portfolio-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	min-height: 245px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ce3e16;
	background-position: 0px 0px, 50% 0px;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, fixed;
}

.button-3.dark-button:hover {
	background-color: #2d3037;
	text-shadow: none;
	color: #fff;
}

.div-slant {
	height: 110px;
	margin-bottom: -1px;
	background-image: url('../images/slant-01.svg');
	background-position: 0px 0px;
	background-size: cover;
}

.section-3-column-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	height: 475px;
	min-height: auto;
	padding-right: 2rem;
	padding-bottom: 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.section-3-column-2 {
	position: relative;
	display: block;
	width: 60%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	text-align: center;
}

.section-3-image-wrapper {
	position: relative;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: auto;
	display: block;
	width: 100%;
	height: 475px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.section-3 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: auto;
	padding-top: 0rem;
	padding-right: 4vw;
	padding-left: 4vw;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: rgba(230, 235, 245, 0.2);
	padding-bottom: 2rem;
}

.button-3.no-margin:hover {
	background-color: #ce3e16;
	text-shadow: none;
	color: #fff;
}

.section-2-column.col-1-and-2 {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	text-decoration: none;
}

.icon-circle-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 133px;
	height: 133px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border: 3px solid #e3e7ee;
	border-radius: 67px;
	border-style: none;
	background-position: 50% 50%;
	background-size: cover !important;
	background-repeat: no-repeat;
}

.learn-moretext-link-wrapper {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 1.25rem;
	padding-left: 1.5vw;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	text-align: center;
}

.learn-more-text-link {
	padding-right: 0rem;
	padding-left: 2rem;
	background-image: url('../images/gray-link-arrow.svg');
	background-position: 0% 50%;
	background-repeat: no-repeat;
	color: #707785;
	font-size: 0.9rem;
	line-height: 1.15rem;
	font-weight: 700;
	letter-spacing: 0.05px;
	text-decoration: none;
	text-transform: uppercase;
}

.section-2-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 40px;
	margin-bottom: 3rem;
	padding-bottom: 3rem;
	border-bottom: 1px solid #e4e4e4;
}

.section-2-cb-heading {
	margin-top: 12px;
	margin-bottom: 5px;
	color: #ce3e16;
	font-size: 1.4rem;
	line-height: 1.85rem;
	font-weight: 400;
	letter-spacing: 0.04rem;
}

.section-2-header {
	display: block;
	max-width: 820px;
	margin-top: 36px;
	margin-right: auto;
	margin-left: auto;
	color: #24272e;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.04rem;
}

.section-2-subhead {
	display: block;
	max-width: 600px;
	margin-right: auto;
	margin-left: auto;
	color: #353840;
	font-size: 1.125rem;
	line-height: 1.625rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.03rem;
	text-transform: none;
}

.section-2-subhead p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	float: none;
}

.section-2-subhead p:last-child,
.cta-bar-header p:last-child {
	margin-bottom: 0px;
}

.section-1.cta-section {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background-color: #24272e;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 39, 46, 0.8)), to(rgba(36, 39, 46, 0.8))), url('../images/pattern-dots.svg');
	background-image: linear-gradient(180deg, rgba(36, 39, 46, 0.8), rgba(36, 39, 46, 0.8)), url('../images/pattern-dots.svg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, 1200px;
	background-repeat: repeat, repeat;
}

.cta-bar-header {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 4rem;
	margin-bottom: 0.5rem;
	color: #fff;
	font-size: 2rem;
	font-weight: 300;
	letter-spacing: 0.04rem;
}

.slider-section {
	box-shadow: inset 0 5px 9px 0 rgb(0 0 0 / 7%);
}

.container.slider-container {
	display: block;
	max-width: 1320px;
	margin-right: auto;
	margin-left: auto;
}

.new-slider {
	height: 575px;
	background-color: transparent;
	padding-bottom: 0px;
}

.new-slider-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding: 3.5rem 3vw 2.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.slide-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-right: 3vw;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.slider-image-div {
	position: relative;
	width: 50%;
	height: 100%;
	background-position: 0px 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.slider-cta-link {
	margin-top: 7px;
	margin-left: 11px;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 30px;
	float: left;
	clear: left;
	background-image: url('../images/gray-link-arrow.svg');
	background-position: 0px 50%;
	background-size: 21px 21px;
	background-repeat: no-repeat;
	color: #595d63;
	font-size: 1rem;
	line-height: 1.125rem;
	font-weight: 700;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.new-slider-arrow-link {
	display: block;
	margin-right: -80px;
	margin-left: -80px;
	opacity: 0.3;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	color: #595d63;
}

.slider-paragraph h2,
.slider-paragraph h1,
.slider-paragraph h3,
.slider-paragraph h4,
.slider-paragraph h5 {
	background-color: transparent;
	color: #17191d;
	font-family: 'Open Sans', sans-serif;
	text-transform: none;
	font-weight: 400;
}

.slider-paragraph {
	max-width: 450px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	margin-left: 0;
	float: left;
	clear: left;
	color: #17191d;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.slider-cta-link:hover {
	background-image: url('../images/red-link-arrow.svg');
	opacity: 1;
	color: #ce3e16;
}

.learn-more-text-link:hover {
	background-image: url('../images/red-link-arrow.svg');
	color: #ce3e16;
}

.mobile-navigation-menu li a.w-nav-link:hover,
.mobile-navigation-menu li a.mm-btn_next:hover {
	background-color: #353840;
	box-shadow: none;
	color: #fff;
}

.mobile-navigation-menu {
	background: #24272e;
}

@media screen and (max-width: 991px) {
	.slider-paragraph {
		display: block;
		margin-top: 1rem;
		font-size: 1.125rem;
		line-height: 1.625rem;
	}

	.button-3.dark-button {
		max-width: 300px;
		float: none;
	}

	.portfolio-cta-paragraph {
		position: static;
		left: 0px;
		margin-top: 10px;
		float: none;
		font-size: 20px;
	}

	.portfolio-text-wrapper h2 {
		font-size: 36px;
		line-height: 42px;
	}

	.button-3.dark-button {
		max-width: 300px;
		float: none;
	}

	.section-1-container.portfolio-container {
		padding-top: 0px;
	}

	.section-1.portfolio-section {
		min-height: auto;
		height: auto;
		background-position: 0px 0px, 0px 0px, 0px 0px;
	}

	.div-slant {
		margin-bottom: -1px;
	}

	.section-3-column-1 {
		width: 100%;
		height: auto;
		min-height: 0px;
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.section-3-column-2 {
		width: 100%;
		padding-right: 10px;
		padding-left: 10px;
	}

	.section-3-image-wrapper {
		padding-top: 1rem;
	}

	.section-3 {
		display: block;
	}

	.section-1.portfolio-section {
		background-position: 0px 0px, 0px 0px, 0px 0px;
	}

	.section-2-column.col-1-and-2 {
		margin-bottom: 60px;
		padding-right: 5vw;
		padding-bottom: 30px;
		padding-left: 5vw;
	}

	.section-2-column.col-2-and-3 {
		margin-bottom: 60px;
	}

	.section-2-paragraph {
		min-width: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
		text-align: center;
	}

	.learn-moretext-link-wrapper {
		margin-left: 0rem;
		padding-left: 0vw;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
	}

	.section-2-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		margin-bottom: 0rem;
		padding-right: 4vw;
		padding-bottom: 0rem;
		padding-left: 4vw;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-style: none;
	}

	.section-2-row.row-2-last {
		padding-bottom: 2rem;
	}

	.section-2-header-wrapper {
		margin-bottom: 3rem;
	}

	.section-2-header {
		font-size: 2.375rem;
		line-height: 3rem;
	}

	.section-1.cta-section {
		display: block;
		padding-right: 0rem;
		padding-left: 0rem;
		background-color: #24272e;
	}

	.cta-bar-header {
		display: inline-block;
		margin-right: 2rem;
		font-size: 1.5rem;
		line-height: 2.35rem;
		text-align: left;
	}

	.container.slider-container {
		max-width: 768px;
		min-width: 0px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.new-slider {
		height: 400px;
	}

	.new-slider-content-wrap {
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.slide-text-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 56.5%;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.slider-image-div {
		position: relative;
		width: 50%;
		height: 100%;
		background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
		background-position: 0px 50%;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.slider-cta-link {
		font-size: 0.95rem;
		line-height: 1rem;
	}

	.slider-paragraph {
		margin-top: 0px;
		margin-left: 0px;
	}

	.new-slider-arrow-link {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.portfolio-text-wrapper h2 {
		font-size: 30px;
		line-height: 36px;
		text-align: center;
	}

	.section-1.portfolio-section {
		height: auto;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.portfolio-text-wrapper {
		min-width: auto;
	}

	.section-3-column-1 {
		padding-bottom: 24px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.section-3-column-2 {
		display: block;
		padding-right: 0px;
		padding-left: 0px;
	}

	.section-3-image-wrapper {
		height: 375px;
		padding-right: 10px;
		padding-left: 10px;
		background-position: 50% 50%;
		background-size: contain;
	}

	.section-2-column.col-1-and-2 {
		margin-bottom: 50px;
		padding-bottom: 30px;
	}

	.section-2-column.col-2-and-3 {
		margin-bottom: 50px;
	}

	.section-2-cb-heading {
		line-height: 2.375rem;
	}

	.section-2-paragraph {
		display: block;
		font-size: 0.95rem;
		line-height: 1.55rem;
		text-align: center;
	}

	.section-2-cb-heading {
		line-height: 2.375rem;
	}

	.section-1.cta-section {
		height: auto;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.cta-bar-header {
		margin-right: 0rem;
		margin-bottom: 1rem;
		float: none;
		line-height: 2rem;
		text-align: center;
	}

	.new-slider {
		height: 280px;
	}

	.slider-image-div {
		top: 20px;
		right: 0px;
		width: 260px;
	}

	.slider-cta-link {
		position: static;
		margin-top: 10%;
		padding-left: 26px;
		background-size: 18px 18px;
		font-size: 14px;
	}

	.slider-paragraph {
		margin-top: 0px;
		margin-left: 0px;
	}
}

@media screen and (max-width: 479px) {
	.portfolio-text-wrapper {
		min-width: 0px;
	}

	.portfolio-cta-paragraph {
		color: #000;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
	}

	.portfolio-text-wrapper h2 {
		text-align: center;
	}

	.section-1.portfolio-section {
		height: auto;
	}

	.button-3.no-margin {
		margin-right: 0rem;
	}

	.section-3-column-1 {
		padding-bottom: 16px;
	}

	.section-3-column-2 {
		padding-right: 0px;
		padding-left: 0px;
		margin-bottom: 20px;
	}

	.section-3-image-wrapper {
		height: 225px;
	}

	.section-2-paragraph {
		width: 100%;
	}

	.more-details-link {
		position: relative;
		margin-right: 0px;
	}

	.section-2-row.row-2-last {
		padding-bottom: 1rem;
	}

	.section-2-subhead {
		font-size: 1rem;
	}

	.cta-bar-header {
		text-align: center;
	}

	.new-slider {
		height: auto;
		padding-bottom: 2.5rem;
	}

	.new-slider-content-wrap {
		height: auto;
		padding: 2rem 0vw 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.slide-text-wrap {
		width: 100%;
		margin-bottom: 1.5rem;
		padding-right: 0vw;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slider-image-div {
		position: static;
		display: block;
		width: 240px;
		margin-right: auto;
		margin-left: auto;
	}

	.slider-cta-link {
		position: static;
		margin-top: 10%;
		padding-left: 26px;
		background-size: 18px 18px;
		font-size: 14px;
	}

	.slider-paragraph {
		margin-bottom: 0px;
	}
}

/* header */
.main-content-wrapper {
	position: relative;
	z-index: 1000;
	padding-top: 85px;
}

.nav-bar-widget {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 4px solid #fff;
	background-color: #fff;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	box-shadow: 4px 4px 7px 0 rgb(3 4 5 / 20%);
}

.header-container {
	width: 100%;
	max-width: 1400px;
	margin-right: 4vw;
	margin-left: 4vw;
	padding-right: 0rem;
	padding-left: 0rem;
}

.logo-home-link {
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 265px;
	margin-top: 10px;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.accesible-navigation-menu ul.nav-menu {
	margin-bottom: 0px;
	margin-top: 10px;
}

.secondary-nav-wrap {
	margin-top: 15px;
	float: right;
}

.vertical-pipe {
	display: inline-block;
	margin-right: 12px;
	padding-right: 2px;
	padding-left: 3px;
	float: left;
	line-height: 12px;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper {
	margin-top: -6px;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper ul {
	border-top: 5px solid #ce3e16;
	background-color: #24272e;
	box-shadow: 0 3px 5px 0 rgb(0 0 0 / 50%);
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper ul li a {
	border-bottom: 1px none rgba(170, 175, 184, 0.5);
	color: #fff;
	font-size: 0.9rem;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	line-height: 1.2rem;
	padding-right: 0.75rem;
	padding-left: 0.625rem;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.accesible-navigation-menu ul.nav-menu li.main-nav-dd-toggle .container-wrapper ul li a:hover {
	background-color: #353840;
	color: #fff;
}

.main-nav-dd-toggle:hover {
	box-shadow: inset 0 -5px 0 0 #ce3e16;
	color: #616163;
}

.main-nav-dd-toggle.has-dropdown:hover {
	box-shadow: none;
	color: #616163;
}

@media (max-width: 1174px) and (min-width: 992px) {
	.main-nav-link {
		padding: 10px 7px;
		font-size: 0.93rem;
	}
}

@media (max-width: 991px) {
	.secondary-nav-wrap {
		display: none;
		margin-right: 15px;
		line-height: 16px;
	}

	.logo-home-link {
		position: relative;
		padding-top: 10px;
		padding-bottom: 5px;
		width: 265px;
		margin-top: 0;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-content-wrapper {
		padding-top: 76px;
	}
}

@media (max-width: 767px) {
	.logo-home-link {
		margin-bottom: 5px;
	}
}

@media (max-width: 479px) {
	.logo-home-link {
		margin-bottom: 5px;
		width: 225px;
	}

	.main-content-wrapper {
		padding-top: 62px;
	}
}


/* footer */
.footer-section {
	position: relative;
	background-color: #24272e;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 39, 46, 0.8)), to(rgba(36, 39, 46, 0.8))), url('../images/pattern-dots.svg');
	background-image: linear-gradient(180deg, rgba(36, 39, 46, 0.8), rgba(36, 39, 46, 0.8)), url('../images/pattern-dots.svg');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 1200px;
	background-repeat: repeat, repeat;
	box-shadow: inset 0 5px 9px 0 rgb(0 0 0 / 7%);
}

.signoff-dark-bar {
	background-color: rgba(0, 0, 0, 0.25);
}

.footer-row-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 16px;
	margin-bottom: 16px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-row-2-column-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-row-2-column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.grip-footer-lockup {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.back-to-top {
	position: absolute;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 20px;
	padding: 15px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 50px;
	border-top-right-radius: 50px;
	background-color: #ce3e16;
	opacity: 1;
	-webkit-transition-property: all;
	transition-property: all;
	-webkit-transition-duration: 300ms;
	transition-duration: 300ms;
	text-decoration: none;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 0.45;
}

.vertical-pipe {
	display: inline-block;
	margin-right: 5px;
	margin-left: 5px;
	padding-right: 2px;
	padding-left: 2px;
	float: left;
	line-height: 12px;
}

.signoff-text {
	margin-top: 6px;
	margin-right: 4px;
	float: left;
	color: #747479;
	font-size: 12px;
	line-height: 16px;
}

.footer-row-1 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 38px;
	margin-bottom: 40px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-left-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.footer-column-1 {
	margin-right: 3rem;
	padding-left: 0px;
}

.footer-logo-link-block {
	width: 200px;
}

.map-pin-wrapper {
	display: none;
	margin-top: -3px;
	margin-right: 8px;
	margin-bottom: 40px;
	float: left;
}

.footer-paragraph {
	float: left;
	color: #9a9da4;
	font-size: 0.875rem;
	line-height: 1.5rem;
	letter-spacing: 0.01rem;
}

.footer-column-3 {
	width: 24%;
	min-height: 106px;
	padding-bottom: 16px;
	padding-left: 16px;
	border-left: 1px none #595959;
}

.div-block-6 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-nav-list {
	margin-bottom: 0px;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.footer-nav-list-item {
	letter-spacing: 0.02rem;
}

.footer-list-link {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #9a9da4;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-list-link:hover {
	color: #fff;
}

.footer-column-3 {
	width: 24%;
	min-height: 106px;
	padding-bottom: 16px;
	padding-left: 16px;
	border-left: 1px none #595959;
}

@media screen and (max-width: 991px) {
	.footer-section {
		background-size: cover;
		background-repeat: no-repeat;
	}

	.signoff-dark-bar {
		background-color: #24272e;
	}

	.footer-row-2-column-1 {
		margin-bottom: 0.75rem;
		padding-right: 5px;
	}

	.grip-footer-lockup {
		float: left;
	}

	.social-media-wrapper {
		margin-right: 5px;
	}

	.signoff-text {
		padding-right: 0px;
		padding-left: 5px;
	}

	.footer-row-1 {
		margin-bottom: 32px;
	}

	.footer-left-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-column-1 {
		padding-left: 0px;
	}

	.footer-column-2 {
		margin-top: 10px;
	}

	.footer-paragraph {
		line-height: 24px;
	}

	.footer-column-3 {
		width: 40%;
		padding-right: 0px;
		padding-bottom: 32px;
	}

	.footer-list-link {
		line-height: 24px;
		letter-spacing: 0.02rem;
	}

	.footer-column-3 {
		width: 40%;
		padding-right: 0px;
		padding-bottom: 32px;
	}

	#GRIPFooterLogo {
		margin-top: 0px;
	}
}

@media screen and (max-width: 767px) {
	.footer-section {
		background-color: #272a30;
		background-image: none;
	}

	.footer-row-2 {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-row-2-column-1 {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width: 100%;
		padding-right: 0;
	}

	.footer-row-2-column-2 {
		width: 100%;
		margin-top: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.grip-footer-lockup {
		float: none;
	}

	.back-to-top {
		width: 60px;
		height: 60px;
	}

	.social-media-wrapper {
		width: 100%;
		margin-bottom: 10px;
		float: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
		margin-right: 0px;
	}

	.social-media-link-block {
		margin-right: 10px;
		margin-left: 10px;
	}

	.vertical-pipe {
		display: none;
	}

	.signoff-text {
		float: none;
		text-align: center;
	}

	.footer-row-1 {
		padding-right: 34px;
		padding-left: 34px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-left-wrap {
		width: 100%;
	}

	.footer-column-1 {
		padding-left: 0px;
	}

	.footer-column-2 {
		margin-bottom: 28px;
		padding-bottom: 16px;
		border-bottom: 1px solid #595959;
		text-align: left;
	}

	.map-pin-wrapper {
		margin-bottom: 50px;
	}

	.footer-column-3 {
		width: 100%;
		padding-bottom: 0px;
		padding-left: 0px;
		border-left: 1px none #000;
	}

	.footer-column-3 {
		width: 100%;
		padding-bottom: 0px;
		padding-left: 0px;
		border-left: 1px none #000;
	}

	#GRIPFooterLogo {
		margin-top: 0px;
	}
}

@media screen and (max-width: 479px) {
	.footer-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.footer-row-2-column-1 {
		position: static;
	}

	.grip-footer-lockup {
		float: none;
	}

	.back-to-top {
		margin-right: 0px;
	}

	.social-media-wrapper {
		margin-top: 10px;
		margin-right: 0px;
		margin-bottom: 10px;
		float: none;
		text-align: center;
	}

	.social-media-link-block {
		margin-right: 10px;
		margin-left: 10px;
	}

	.signoff-text {
		float: none;
		text-align: center;
	}

	.footer-row-1 {
		padding-right: 15px;
		padding-left: 15px;
	}

	.footer-column-2 {
		margin-bottom: 16px;
		border-bottom: 1px solid #595959;
	}

	.map-pin-wrapper {
		margin-right: 7px;
		margin-bottom: 190px;
	}

	.footer-paragraph {
		display: none;
		margin-bottom: 0px;
		float: none;
	}

	.footer-column-3 {
		border-style: none;
	}

	.footer-column-3 {
		border-style: none;
	}
}

/* Solution Categories sticcky navigation */
.sticky-fast-travel-wrapper {
	position: -webkit-sticky;
	position: sticky;
	top: 85px;
	overflow: hidden;
	width: 100%;
	height: 60px;
	border-top: 5px solid #ce3e16;
	background-color: #2d3037;
	box-shadow: 4px 4px 7px 0 rgb(3 4 5 / 20%);
	z-index: 100;
}

.sticky-fast-travel-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.fast-travel-heading {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #d43f16;
	color: #000;
	line-height: 43px;
	font-weight: 700;
}

.fast-travel-heading a,
.fast-travel-heading a:hover {
	color: #000 !important;
	background-color: #d43f16 !important;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	text-decoration: none;
}


.fast-travel-slider {
	position: relative;
	flex-grow: 1;
	height: 55px;
	margin-right: auto;
	margin-left: auto;
	clear: both;
	color: #e6ebf5;
}

.fast-travel-heading .icon {
	display: none;
	width: 36px;
	height: 55px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.fast-travel-mask {
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 100%;
	margin-right: 40px;
	margin-left: 28px;
	white-space: nowrap;
}

.fast-travel-text {
	position: relative;
	display: inline-block;
	width: auto;
	height: 100%;
	padding: 15px 20px 5px;
	-webkit-box-ordinal-group: 4;
	-webkit-order: 3;
	-ms-flex-order: 3;
	order: 3;
	-webkit-transition: box-shadow 200ms ease;
	transition: box-shadow 200ms ease;
	color: #e6ebf5;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: left;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
}

.fast-travel-text:hover {
	box-shadow: inset 0 -6px 0 0 #ce3e16;
	color: #e6ebf5;
}

.fast-travel-text.active {
	box-shadow: inset 0 -6px 0 0 #a6b0c0;
	-webkit-transition-property: all;
	transition-property: all;
	color: #ee7553;
}

.fast-travel-text.active:hover {
	background-color: #d43f16;
	color: #000;
	font-weight: 600;
}

.fast-travel-mask .fast-travel-text:last-child {
	margin-right: 40px;
}

.fast-travel-nav {
	position: absolute;
	right: 0px;
	z-index: 2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #2d3037;
}

.fast-travel-nav,
.fast-travel-heading .icon {
	font-family: 'webflow-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 2rem;
}


#fast-travel-nav-right,
#fast-travel-nav-left {
	background: transparent;
	display: inline-block;
	width: auto;
	height: 100%;
	line-height: 55px;
	padding: 0;
	margin: 0;
}

.fast-travel-heading:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 23px;
	right: -23px;
	background: #d43f16;
	-webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
	clip-path: polygon(100% 50%, 0 0, 0 100%);
	transform: translate(-1px, 0px);
	color: #000;
}

.fast-travel-nav:before {
	content: '';
	display: block;
	background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(45, 48, 55, 1) 100%);
	height: 100%;
	position: absolute;
	left: -30px;
	width: 30px;
}

.sticky-fast-travel-wrapper.sticky {
	position: fixed;
}

@media screen and (min-width: 768px) {
	.sticky-fast-travel-wrapper.no-category .fast-travel-heading {
		display: none;
	}
}

@media screen and (max-width: 991px) {
	.sticky-fast-travel-wrapper {
		top: 75px;
	}

	.fast-travel-mask {
		margin-left: 30px;
	}

	.fast-travel-text {
		padding-right: 15px;
		padding-left: 15px;
	}
}

@media screen and (max-width: 767px) {
	.sticky-fast-travel-wrapper {
		top: 75px;
		overflow: visible;
		height: auto;
		text-align: center;
	}

	.sticky-fast-travel-container {
		overflow: visible;
		height: 50px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		cursor: pointer;
	}

	.fast-travel-heading {
		position: static;
		z-index: 999;
		display: inline-block;
		background-color: transparent;
		color: #e6ebf5;
		font-size: 1rem;
		line-height: 50px;
	}

	.fast-travel-heading .icon {
		font-size: 1rem;
		display: inline;
	}

	.fast-travel-slider {
		position: absolute;
		top: 50px;
		z-index: 998;
		width: 100%;
		height: auto;
		background-color: #2d3037;
		transition: opacity 1s ease-out;
		opacity: 0;
		height: 0;
		overflow: hidden;
	}

	.fast-travel-mask {
		position: relative;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 100%;
		height: 90vh;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
		padding-bottom: 50px;
	}

	.sticky-fast-travel-wrapper:hover .fast-travel-slider {
		opacity: 1;
		height: auto;
	}

	.sticky-fast-travel-wrapper:hover .fast-travel-heading {
		color: #f8992a;
	}

	.fast-travel-text {
		width: 100%;
		height: auto;
		padding: 8px 10px;
		-webkit-transition-property: all;
		transition-property: all;
		font-size: 0.75rem;
		text-align: center;
	}

	.fast-travel-nav {
		display: none;
	}

	#fast-travel-nav-right,
	#fast-travel-nav-left {
		line-height: 50px;
		font-size: 1.5rem;
	}

	.fast-travel-text.active {
		box-shadow: none;
	}

	.fast-travel-text:hover {
		background-color: #f8992a;
		box-shadow: none;
		color: #17191d;
	}

	.fast-travel-heading:after {
		display: none;
	}

	.fast-travel-mask .fast-travel-text:last-child {
		margin-right: 0px;
	}
}

@media screen and (max-width: 479px) {
	.sticky-fast-travel-wrapper {
		top: 68px;
	}

	.fast-travel-text {
		white-space: nowrap;
	}
}


/* partners carousel */
#partners_module.homepage-content-box.partners-carousel {
	width: calc(100% - 120px);
	margin: 0 auto;
}

#partners_module.homepage-content-box.partners-carousel .partner-left-arrow {
	display: block;
	margin-right: -80px;
	margin-left: -80px;
	opacity: 0.3;
	-webkit-transition: opacity 0.2s ease;
	transition: opacity 0.2s ease;
	color: #595d63;
}

#partners_module.homepage-content-box.partners-carousel .partner-right-arrow {
	display: block;
	margin-right: -80px;
	margin-left: -80px;
	opacity: 0.3;
	-webkit-transition: opacity 0.2s ease;
	transition: opacity 0.2s ease;
	color: #595d63;
}

#partners_module.homepage-content-box.partners-carousel .partner-left-arrow:hover,
#partners_module.homepage-content-box.partners-carousel .partner-right-arrow:hover {
	opacity: 1;
}

#partners_module.homepage-content-box.partners-carousel .partner-image-link-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-top: 0;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

#partners_module.homepage-content-box.partners-carousel .partner-image-link {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 125px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

#partners_module.homepage-content-box.partners-carousel .partner-image {
	display: block;
	max-height: 115px;
	max-width: 220px;
	margin-right: auto;
	margin-left: auto;
}

.section-2-column.col-2-and-3 {
	text-decoration: none;
}


/* search */
.menu-container {
	display: flex;
	align-items: center;
	justify-content: center;
	float: right;
	margin-top: 10px;
}

.search-form {
	max-width: 200px;
	margin-bottom: 0;
	display: flex;
	position: relative;
	margin-top: 8px;
}

.search-input {
	border-radius: 30px;
	height: 44px;
	margin-bottom: 0;
}

.search-input:active,
.search-input:focus {
	border: 1px solid #f8992a;
}

.search-button {
	background-color: #ce3e16;
	background-image: url("/assets/images/white_search.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 20px 20px;
	border-radius: 30px;
	width: 40px;
	height: 40px;
	margin-top: 2px;
	margin-right: 2px;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
	font-size: 0px;
}

.mobile-navigation-menu li.search-mobile-container {
	padding-top: 15px;
	padding-bottom: 15px;
}

.mobile-navigation-menu li.search-mobile-container:after {
	border-color: transparent;
}

.search-button:hover {
	background-color: #2d3037;
}

@media screen and (max-width: 1378px) and (min-width: 992px) {
	.header-container {
		display: flex;
	}

	.menu-container,
	.accesible-navigation-menu {
		flex-grow: 1;
	}

	.accesible-navigation-menu {
		min-width: 574px;
	}

	.main-nav-link {
		padding: 10px 5px;
		font-size: 0.95rem;
	}
}


@media screen and (max-width: 1193px) and (min-width: 992px) {
	.main-nav-link {
		padding: 10px 3px;
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 991px) {
	.search-form {
		width: 65%;
		max-width: none;
		margin: 0 auto;
	}

	.menu-container {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.search-form {
		width: 80%;
	}
}
